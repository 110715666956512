.agenda__title {
  text-align: center;
  margin: 52px 0 64px 0;
  font: normal normal bold 45px/40px nudista-web;
  letter-spacing: 0.9px;
  color: #1a214d;
}
.btn__scroll {
  background: #1a214d 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: normal normal 600 16px/19px nudista-web;
  color: #ffffff;
  padding: 16px 52px;
  margin: 50px 0 83px 0;
  text-transform: uppercase;
}
.btn:hover {
  color: #ffffff;
  background-color: #4a5dd9;
  border-color: #4a5dd9;
}
.cards_agenda {
  display: flex;
  justify-content: center;
  gap: 1.3rem;
  flex-wrap: wrap;
}
