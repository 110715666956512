.app-header {
  margin-top: 57px;
}
.header__upperSubTitle {
  font: normal normal 600 24px/32px nudista-web;
  letter-spacing: 0px;
  color: #1a214d;
}
.header__mainTitle {
  font: normal normal bold 45px/54px nudista-web;
  letter-spacing: 0px;
  color: #1a214d;
}
.header__lowerSubTitle {
  font: normal normal 300 45px/54px nudista-web;
  letter-spacing: 0px;
  color: #1a214d;
}
.header__card--text {
  color: #1a214d;
  font: normal normal 300 18px/26px nudista-web;
}
.header__card--text--information {
  color: #4a5dd9;
  text-transform: uppercase;
  font: normal normal bold 14px/28px nudista-web;
  padding-bottom: 0;
  margin-bottom: 0;
}
.font-weight-bold {
  font-weight: 600;
}
