footer {
  background-color: #4a5dd9;
  width: 100%;
  min-height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__wrapper {
  color: white;
  font: normal normal 300 16px/19px nudista-web;
}
.footer__nav {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0;
}
.footer__nav__element:last-of-type {
  margin-left: auto;
}
.referalnumber::after {
  content: "PL-RD-046-11/2022";
  display: block;
  font-size: 0.8rem;
  text-align: right;
}
@media (max-width: 800px) {
  .footer__nav {
    flex-flow: wrap column;
    justify-content: center;
    margin: 2rem auto;
  }
  .footer__nav__element:last-of-type {
    margin-left: 0;
  }
}
.footer__link {
  color: inherit;
  text-decoration: none;
}
.footer__link:hover {
  color: #1a214d;
  transition: 0.3s;
}
