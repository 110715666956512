.card__wrapper {
  background-color: #f2f3f8;
  padding: 66px 20px 39px 40px;
  width: 342px;
  height: 385px;
  display: block;
  position: relative;
}
.card__number {
  position: absolute;
  top: -37px;
  left: 40px;
  font: normal normal bold 36px/43px nudista-web;
  padding: 13px;
  background-color: #4a5dd9;
  color: white;
  border-radius: 50%;
  width: 69px;
  height: 69px;
  margin: 0;
  text-align: center;
}
.card__content {
  font: normal normal 300 18px/28px nudista-web;
  color: #1a214d;
}
.card__time {
  font: normal normal 600 22px/27px nudista-web;
}
.card__cols {
  font: normal normal bold 18px/24px nudista-web;
}
.card__agenda {
  color: #4a5dd9;
}
.card__agenda__element:not(:last-of-type) {
  padding-bottom: 16px;
}
.card__agenda span {
  color: #1a214d;
}
