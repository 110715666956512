.App__main {
  background-image: url("./player-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  min-height: 642px;
  margin-top: 159px;
  position: relative;
}
.iframe-wrapper {
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  width: 100%;
  margin: 0;
}
.iframe-wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 5px 5px 13px -1px black;
  transform: translateY(-70px);
}
.iframe-wrapper img {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  box-shadow: 5px 5px 13px -1px black;
  transform: translateY(-70px);
}
.iframe-wrapper.chat {
  height: 87%;
  padding-bottom: 0;
  position: relative;
  width: 100%;
  margin: 0;
}
.player__text {
  padding-left: 28px;
  color: #ffffff;
  font: normal normal 300 18px/28px nudista-web;
}
.arrow_down {
  position: absolute;
  bottom: 20px;
  left: 50%;
  border: solid #ffffff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(42deg) translate(-50%, -50%);
  opacity: 0.6;
  border-radius: 2px;
  cursor: pointer;
}
.arrow_down:hover {
  animation-name: arrow-bounce;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@keyframes arrow-bounce {
  from {
    transform: rotate(42deg) translate(-50%, -50%);
  }
  to {
    transform: rotate(42deg) translate(-50%, -50%) scale(1.3);
  }
}
.mobile-chat {
  display: flex;
  justify-content: center;
  margin: 4rem auto;
}
.iframe--position {
  min-height: 340px;
}
.mobile--view {
  display: block;
}
@media (max-width: 767px) {
  .mobile--view {
    display: none;
  }
}
.lds-roller {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 25%;
  left: 50%;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
